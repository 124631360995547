import Base from '@components/layouts/base';
import Footer from '@components/partials/common/footer';
import Header from '@components/partials/common/header';
import Pagination from '@components/partials/common/pagination';
import ModelGrid from '@components/partials/list/modelGrid';
import { getCategoryApi } from '@data/categoryApi';
import { getIndexData } from '@data/index';
import styles from '@styles/scss/list/_listPage.module.scss';
import { Banner } from 'lib/types/item/banner';
import { Category } from 'lib/types/item/category';
import { FooterText } from 'lib/types/item/footer';
import { FooterLinks } from 'lib/types/item/footerLinks';
import { GenderButton } from 'lib/types/item/genderButtons';
import { Meta } from 'lib/types/item/meta';
import { NavbarItem } from 'lib/types/item/navBarItem';
import { PageMeta } from 'lib/types/item/pageMeta';
import { PerformerCard } from 'lib/types/item/performerCard';
import { SidebarItem } from 'lib/types/item/sideBarItem';
import { GetServerSideProps } from 'next';
import Head from 'next/head';

import { IndexProps } from '../lib/types/page/indexprops';

export default function Index({
    pageMeta,
    performerCards,
    category,
    navBarItem,
    sideBarItem,
    stickyFooter,
    banner,
    footer,
    footerLinks,
    genderButtons,
    meta,
    gender,
    language
}: {
    pageMeta: PageMeta;
    pageData: IndexProps;
    performerCards: PerformerCard[];
    category: Category;
    navBarItem: NavbarItem[];
    sideBarItem: SidebarItem[];
    stickyFooter: string;
    banner: Banner;
    footer: FooterText;
    footerLinks: FooterLinks;
    genderButtons: GenderButton;
    meta: Meta;
    gender: string;
    language: string;
}): JSX.Element {
    return (
        <>
            <Base currentLang={language} performer="">
                <Head>
                    <title>{meta.meta_title}</title>
                    <meta name="description" content={meta.meta_description} />
                    <meta property="og:type" content="website" />
                    <meta property="og:site_name" content={meta.meta_title} />
                    <meta property="og:url" content={meta.canonical} />
                    <meta
                        property="og:description"
                        content={meta.meta_description}
                        key="description"
                    />
                    <meta property="og:title" name="title" content={meta.meta_title} />
                    <meta name="robots" content={meta.robots} />
                    <link
                        rel="canonical"
                        href={
                            language === 'en'
                                ? meta.canonical + (pageMeta.page > 1 ? pageMeta.page : '')
                                : `${
                                      meta.canonical.replace('.sex/', `.sex/${language}/`) +
                                      (pageMeta.page > 1 ? pageMeta.page + '/' : '')
                                  }`
                        }
                    />
                    {pageMeta.page < pageMeta.totalPages && (
                        <link
                            rel="next"
                            href={
                                language === 'en'
                                    ? meta.canonical + (pageMeta.page + 1) + '/'
                                    : `${
                                          meta.canonical.replace('.sex/', `.sex/${language}/`) +
                                          (pageMeta.page + 1) +
                                          '/'
                                      } `
                            }
                        />
                    )}
                    {pageMeta.page === 1 ? null : pageMeta.page > 2 ? (
                        <link
                            rel="prev"
                            href={
                                language === 'en'
                                    ? meta.canonical + (pageMeta.page - 1) + '/'
                                    : `${
                                          meta.canonical.replace('.sex/', `.sex/${language}/`) +
                                          (pageMeta.page - 1) +
                                          '/'
                                      } `
                            }
                        />
                    ) : (
                        <link
                            rel="prev"
                            href={
                                language === 'en'
                                    ? meta.canonical
                                    : `${meta.canonical.replace('.sex/', `.sex/${language}/`)} `
                            }
                        />
                    )}
                    <link
                        rel="alternate"
                        href={meta.canonical + (pageMeta.page > 1 ? pageMeta.page : '')}
                        hrefLang="x-default"
                    />
                    {meta.alternate.map((alt) => (
                        <link
                            key={alt}
                            rel="alternate"
                            hrefLang={alt.replace('/', '')}
                            href={`${
                                meta.canonical.replace(
                                    '.sex/',
                                    `.sex/${alt === 'en/' ? '' : alt}`
                                ) + (pageMeta.page > 1 ? pageMeta.page + '/' : '')
                            }`}
                        />
                    ))}
                </Head>
                <Header
                    sideBarItems={sideBarItem}
                    navBarItems={navBarItem}
                    currentLang={language}
                    gender={gender}
                />
                <div className={styles.listPage}>
                    <div className={styles.listContainer}>
                        <ModelGrid
                            performerCards={performerCards}
                            category={category}
                            banner={banner}
                            genderButtons={genderButtons}
                            currentLang={language}
                            gender={gender}
                        />
                    </div>
                    <a
                        href={`https://rabbits.webcam/?id=2333&product_id=36&tag=signup&passthru1=${gender}&passthru2=${language}&exid={exid}&tfid={tfid}`}
                        data-event-category="Outbound Click"
                        data-event-action="Join Click"
                        data-event-label="Widget-Text"
                        aria-label="widget">
                        <div className={styles.stickyBottomButton}>
                            <span>
                                <div className={styles.stickyBottomText}>{stickyFooter}</div>
                            </span>
                        </div>
                    </a>
                </div>
                <Pagination pageMeta={pageMeta} />
                <Footer
                    footerText={footer}
                    footerLinks={footerLinks}
                    currentLang={language}
                    gender={gender}
                />
            </Base>
        </>
    );
}

export const getServerSideProps: GetServerSideProps = async (context: any) => {
    const category = 'home';
    const pageData = await getIndexData();
    const language = context.locale;
    const categoryApi = await getCategoryApi(category, language, '');
    if (!pageData) {
        return {
            notFound: true
        };
    }

    return {
        props: {
            pageMeta: categoryApi.categoryData.pageMeta,
            category: categoryApi.categoryData.category,
            stickyFooter: categoryApi.categoryData.category.sticky_footer,
            banner: categoryApi.categoryData.category.banner,
            footer: categoryApi.categoryData.category.footer,
            sideBarItem: categoryApi.categoryData.sidebarItems,
            performerCards: categoryApi.categoryData.performerCards,
            navBarItem: categoryApi.categoryData.navbarItems,
            footerLinks: categoryApi.categoryData.footerLinks,
            genderButtons: categoryApi.genderLanguageData,
            meta: categoryApi.categoryData.category.meta,
            gender: categoryApi.categoryData.gender,
            language: language,
            pageData
        }
    };
};
